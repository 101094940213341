import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch, useStore } from "react-redux";
import Header from "./Header";
import Footer from "./Footer";
import StripeCheckout from "react-stripe-checkout";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { Button, Container, Row, Col, Form } from "react-bootstrap";
import { injectStripe } from "react-stripe-elements";
import CardSection from "./CardSection";
import { fetchCountries } from "../Redux/GetCountries/GetCountriesActions";
import { fetchStates } from "../Redux/GetStates/GetStatesActions";
import { fetchAddress } from "../Redux/GetAddress/GetAddressActions";
import { updateShippingMethod, getShippingMethod } from "../Redux/UpdateShippingMethod/UpdateShippingMethodActions";
import { fetchBucket } from "../Redux/Bucket/BucketActions";
import { updateItemQuantity } from "../Redux/UpdateItemQuantity/UpdateItemQuantityActions";
import { addTip } from "../Redux/AddTip/AddTipActions";
import { applyCoupon } from "../Redux/ApplyCoupon/ApplyCouponActions";
import { applyGiftCard } from "../Redux/ApplyCoupon/ApplyCouponActions";
import { removeCoupon } from "../Redux/RemoveCoupon/RemoveCouponActions";
import { paymentCheckout } from "../Redux/PaymentCheckout/PaymentCheckoutActions";
import { addAddress } from "../Redux/AddAddress/AddAddressActions";
import StepZilla from "react-stepzilla";

import moment from 'moment';
import Select from "react-select";
import TimeSelect from "react-time-select";
import DatePicker from "react-datepicker";
import TimePicker from 'react-time-picker'

import "react-datepicker/dist/react-datepicker.css";

function Checkout(props) {

  const isJson = (str) => {
     try{
        JSON.parse(str);
     }catch (e){
        //Error
        //JSON is not okay
        return false;
     }

    return true;
  }


  window.addEventListener('message', function(event) {
        const token = event.data;
        if(isJson(token)) {
          const parsedtoken = JSON.parse(token);
       console.log('token1', JSON.parse(token));
         console.log('token2', parsedtoken.cardconnecttoken);
        if(parsedtoken.cardconnecttoken != undefined && parsedtoken.cardconnecttoken != "") {

          setCardconnectToken(parsedtoken.cardconnecttoken)
          setCardconnectExpiry(parsedtoken.expiry)
        }
        //var mytoken = document.getElementById('mytoken');
        //mytoken.value = token.message;
      }

    }, false);

  // store data access start
  const countries_data = useSelector(state => state.GetCountries);
  const states_data = useSelector(state => state.GetStates);
  const address_data = useSelector(state => state.GetStates);
  const updateShippingMethod_data = useSelector(
    state => state.UpdateShippingMethod
  );
  const bucket_data = useSelector(state => state.Bucket);
  const updateItemQuantity_data = useSelector(
    state => state.UpdateItemQuantity
  );
  const tip_data = useSelector(state => state.AddTip);
  const applyCoupon_data = useSelector(state => state.ApplyCoupon);
  const removeCoupon_data = useSelector(state => state.RemoveCoupon);
  const paymentCheckout_data = useSelector(state => state.PaymentCheckout);
  const addAddress_data = useSelector(state => state.AddAddress);
  // store data access End
  const dispatch = useDispatch(); // for accessing the redux function

  const store = useStore();
  const propsStatePayments = store.getState().PaymentCheckout;
  const propsStateBucket = store.getState().Bucket;
  const propsStateApplyCoupon = store.getState().ApplyCoupon;
  const propsStateRemoveCoupon = store.getState().RemoveCoupon;
  const propsStateAddTip = store.getState().AddTip;
  const propsStateShipping = store.getState().UpdateShippingMethod;
  console.log("propsStateApplyCoupon", propsStateApplyCoupon);

  // component all states define start
  const [finalUserEmail, setFinalUserEmail] = useState("");
  const [finalUserToken, setFinalUserToken] = useState("");
  const [uniqueBucketId, setUniqueBucketId] = useState("");
  const [banner_info, setBanner_info] = useState([]);
  const [delivery_cost, setDelivery_cost] = useState(0);
  console.log("delivery_cost---", delivery_cost);
  const [tip_rate_fees, setTip_rate_fees] = useState([]);
  const [configResponseData, setConfigDciResponseData] = useState({
    stripe_info: [],
    static_resource_endpoint: null,
    static_resource_sufix: null,
    is_shop_open: false,
    static_resource_categories_prefix: null
  });
  const [bucketDciResponseData, setBucketDciResponseData] = useState({
    Detailed_cart: [],
    Detailed_cart_item: [],
    cart_item_tip: [],
    Detailed_cart_checkout_method: [],
    Delivery_method: [],
    pickup_restaurant: [],
    current_shipment_method: null
  });
  const [country_info, setCountry_info] = useState([]);
  const [state_info, setState_info] = useState([]);
  const [selectedStateData, setselectedStateData] = useState([]);
  const [checkout_address_user, setCheckout_address_user] = useState([]);
  const [selected_address, setSelected_address] = useState(null);
  const [delivery_info, setDelivery_info] = useState([]);
  const [bucketInfo, setBucketInfo] = useState([]);
  const [updateItemQuantityInfo, setUpdateItemQuantityInfo] = useState([]);
  const [loadingData, setLoadingData] = useState(null);
  const [applyCoupoon, setApplyCoupoon] = useState(null);
  const [applyCouponAmount, setApplyCouponAmount] = useState(0);
  const [applyCouponState, setApplyCouponState] = useState(false);
  const [applyCouponInfo, setApplyCouponInfo] = useState([]);
  const [couponError, setCouponError] = useState(null);
  const [couponErrorModal, setCouponErrorModal] = useState(false);
  const [bucketErrorModal, setBucketErrorModal] = useState(false);
  const [removeCouponStatus, setRemoveCouponStatus] = useState(false);
  const [inputValues, setInputValues] = useState({
    first_name: "",
    last_name: "",
    telephone: "",
    email: "",
    address: "",
    city: "",
    postal_code: "",
    state: "",
    country: "254",
    notes_restaurant: "",
    cardNumber: "",
    cardCvv: "",
    cardExpMonth: "",
    cardExpYear: "",
    publicKey: null,
    giftcheck: false,
    giftcard_number: "",
    giftcard_pin: ""
  });
  const [gift_card_validate, setGift_card_validate] = useState(null);
  const [gift_card_validate_error, setGift_card_validate_error] = useState(null);

  const [firstname_error, setFirstname_error] = useState(false);
  const [lastname_error, setLastname_error] = useState(false);
  const [phone_error, setPhone_error] = useState(false);
  const [email_error, setEmail_error] = useState(false);
  const [postal_code_error, setPostal_code_error] = useState(false);
  const [order_now_click, setOrder_now_click] = useState(false);
  const [payment_complete, setPayment_complete] = useState(false);
  const [payment_token, setPayment_token] = useState("");
  const [cardconnectToken, setCardconnectToken] = useState("");
  const [cardconnectExpiry, setCardconnectExpiry] = useState("");
  const [stripe_error, setStripe_error] = useState(null);
  const [cart_empty_click, setCart_empty_click] = useState(true);
  const [user_address_id, setUser_address_id] = useState(null);
  const [order_loader, setOrder_loader] = useState(false);
  const [order_info, setOrder_info] = useState([]);
  const [checkout_error, setCheckout_error] = useState(null);
  const [address_info, setAddress_info] = useState([]);
  const [showmodal_cart_empty, setShowmodal_cart_empty] = useState(false);
  const [showmodal_shop_closed, setShowmodal_shop_closed] = useState(false);
  const [availableDates,setAvailableDates] = useState([]);
  const [stripe_info,setStripe_info] = useState([]);
  const [orderaheadSelectedDate, setOrderaheadSelectedDate] = useState({
    selectedDate: null
  });
  const [orderaheadSelectedTime, setOrderaheadSelectedTime] = useState({
    selectedTime: null
  });
  const [orderaheadactive, setOrderaheadactive] = useState([]);
  const [localdate,setLocalDate] = useState(null)
  const [localtime,setLocalTime] = useState(null)
  const [localdateformatted,setLocalDateFormatted] = useState(null)
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());
  const [availableTimes, setAvailableTimes] = useState([]);
  const [current_startTimeHours, setCurrent_startTimeHours] = useState(null);
  const [current_startTimeMinutes, setCurrent_startTimeMinutes] = useState('00');
  const [current_endTimeHours, setCurrent_endTimeHours] = useState('23');
  const [current_endTimeMinutes, setCurrent_endTimeMinutes] = useState('55');
  const [availableShippingMethods,setAvailableShippingMethods] = useState([])
  const [checkoutSectionClass,setCheckoutSectionClass] = useState('')
  const [currentShipSelected,setCurrentShipSelected] = useState('')
  const [showmodaldelivery,setShowmodaldelivery] = useState(false)
  const [currentActiveStep,setCurrentActiveStep] = useState(0)



  const [currentmerchantId, setCurrentmerchantid] = useState(null);

  const stripe_info_callbackFunction = (childdata) =>{
    setStripe_info(childdata)
  }

  const [currentShippingMethodName, setCurrentShippingMethodName] = useState(
    null
  );
  const [footerbuttonClass, setFooterbuttonClass] = useState('addressbtn');
  const orderSuccessCall = () => {
    props.orderSuccessCall();
  };


  console.log("currentShippingMethodName-----", currentShippingMethodName);

  console.log('newprops', props);
  // component all states define END

  // hooks start
  // get user email,user token and bucket id hook start
  useEffect(() => {
    if (
      props &&
      props.merchantInfo &&
      props.merchantInfo.access_token
    ) {
      console.log('merchantInfo', props.merchantInfo);
      const user_email =
        localStorage.getItem("user") === null
          ? "guest@onlinebites.com"
          : localStorage.getItem("user");
      const user_token =
        localStorage.getItem("access_token") === null
          ? props.merchantInfo.access_token
          : localStorage.getItem("access_token");
      const user_local_bucket_id =
        localStorage.getItem("user_local_bucket_id") == null &&
        localStorage.getItem("user_local_bucket_id") == undefined
          ? ""
          : localStorage.getItem("user_local_bucket_id");
      setFinalUserEmail(user_email);
      setFinalUserToken(user_token);
      setUniqueBucketId(user_local_bucket_id);
    }
  }, [props]);
  // get user email,user token and bucket id hook end

  // fetch address api ,hook start
  useEffect(() => {
    if (
      localStorage &&
      localStorage.getItem("user") != null &&
      localStorage.getItem("access_token") != null
    ) {
      const address_info = {
        user_token: localStorage.getItem("access_token"),
        user_email: localStorage.getItem("user")
      };
      dispatch(fetchAddress(address_info));
    }
  }, [dispatch]);
  // fetch address api ,hook End

  // add config data into config const hook start
  useEffect(() => {
    if (
      props &&
      props.configInfo &&
      Object.keys(props.configInfo).length > 0
    ) {
      setConfigDciResponseData({
        stripe_info: props.configInfo,
        static_resource_endpoint:
          props.configInfo &&
          props.configInfo.STATIC_RESOURCE_ENDPOINT
            ? props.configInfo.STATIC_RESOURCE_ENDPOINT
            : null,
        static_resource_sufix:
          props.configInfo &&
          props.configInfo.STATIC_RESOURCE_SUFFIX
            ? props.configInfo.STATIC_RESOURCE_SUFFIX
            : null,
        is_shop_open:
          props.configInfo && props.configInfo.IS_SHOP_OPEN
            ? props.configInfo.IS_SHOP_OPEN
            : false
      });
    }
  }, [props]);
  // add config data into config const hook end

  // get restaurant related information and restaurant menu hook start
    useMemo(() =>{
      if(configResponseData && configResponseData.url_info && Object.keys(configResponseData.url_info).length>0){
        //console.log('configResponseData11', configResponseData);
        const restaurant_info_data = {
          static_resource_endpoint:configResponseData.static_resource_endpoint,
          static_resource_sufix:configResponseData.static_resource_sufix,
          rest_merchant_id:configResponseData.url_info.MERCHANT_ID
        }
        const menulist_info = {
          static_resource_endpoint:configResponseData.static_resource_endpoint,
          static_resource_categories_prefix:configResponseData.static_resource_categories_prefix,
          static_resource_sufix:configResponseData.static_resource_sufix,
          rest_merchant_id:configResponseData.url_info.MERCHANT_ID,
          menu_data:configResponseData.url_info.menu_data
        }
        const localdateparam = `localdate-${configResponseData.url_info.MERCHANT_ID}` ;
        const localtimeparam = `localtime-${configResponseData.url_info.MERCHANT_ID}` ;

        const localdate = localStorage.getItem(localdateparam) ;
        const localtime = JSON.parse(localStorage.getItem(localtimeparam));
        setLocalTime(localtime);
        setLocalDate(localdate);
        const monthName = ["January","February","March","April","May","June","July","August","September","October","November","December"];
        const selectedMonth = monthName[new Date(localdate).getDate()];
        setLocalDateFormatted(`${new Date(localdate).getMonth()} ${selectedMonth} ${new Date(localdate).getFullYear()}`);

      }
    },[configResponseData && configResponseData.url_info])
    // get restaurant related information and restaurant menu hook end

  // add config data into config const hook start
  useEffect(() => {
    if (
      props &&
      props.bucketDciResponseData &&
      props.bucketDciResponseData.length > 0
    ) {
      setBucketDciResponseData({
        Detailed_cart: props.bucketDciResponseData.Detailed_cart,
        Detailed_cart_item:
          props.bucketDciResponseData.Detailed_cart_item,
        cart_item_tip: props.bucketDciResponseData.cart_item_tip,
        Detailed_cart_checkout_method:
          props.bucketDciResponseData.Detailed_cart_checkout_method,
        Delivery_method: availableShippingMethods,
        pickup_restaurant:
          props.bucketDciResponseData.pickup_restaurant,
        current_shipment_method:
          props.bucketDciResponseData.current_shipment_method
      });
    }
  }, [props]);
  // add config data into config const hook end
  useMemo(() => {
    if (props && props.currentShippingMethodName) {
      setCurrentShippingMethodName(props.currentShippingMethodName);
    }
  }, [props]);
  // add deilvery cost props into constant, hook start
  useEffect(() => {
    if (props && props.Delivery_cost) {
      setDelivery_cost(props.Delivery_cost);
    }
  }, [props]);
  // add deilvery cost props into constant, hook end

  // add tip_rate_fees props into constant, hook start
  useEffect(() => {
    if (props && props.tip_rate_fees) {
      setTip_rate_fees(props.tip_rate_fees);
    }
  }, [props]);
  // add tip_rate_fees props into constant, hook End

  // add tip_rate_fees props into constant, hook start
  useEffect(() => {
    if (props && props.localdate) {
      setLocalDate(props.localdate);
    }
  }, [props]);
  // add tip_rate_fees props into constant, hook End

  // add tip_rate_fees props into constant, hook start
  useEffect(() => {
    if (props && props.localtime) {
      setLocalTime(props.localtime);
    }
  }, [props]);
  // add tip_rate_fees props into constant, hook End

  useEffect(() => {
    if (props && props.localdateformatted) {
      setLocalDateFormatted(props.localdateformatted);
    }
  }, [props]);

  // add tip_rate_fees props into constant, hook start
  useEffect(() => {
    if (props && props.merchantid) {
      setCurrentmerchantid(props.merchantid);
    }
  }, [props]);
  // add tip_rate_fees props into constant, hook End

  // add banner_info props into constant, hook start
  useEffect(() => {
    if (props && props.banner_info) {
      setBanner_info(props.banner_info);
    }
  }, [props]);
  // add banner_info props into constant, hook End

  useMemo(() => {
    if (bucketDciResponseData.current_shipment_method != null && bucketDciResponseData && bucketDciResponseData.Delivery_method && bucketDciResponseData.Delivery_method .length > 0) {
      if (
        bucketDciResponseData.current_shipment_method ===
        bucketDciResponseData.pickup_restaurant
      ) {
        setCurrentShippingMethodName("Pickup at Restaurant");
        setDelivery_cost("0");
      } else {
        const filterdata = bucketDciResponseData.Delivery_method.filter(
          item => item.id === bucketDciResponseData.current_shipment_method
        );
        if(filterdata && filterdata.length > 0 && filterdata[0] && filterdata[0].name) {
        setCurrentShippingMethodName(filterdata[0].name);
        setDelivery_cost(filterdata[0].cost);
      }
      }
    }
  }, [bucketDciResponseData.Delivery_method, bucketDciResponseData.current_shipment_method, bucketDciResponseData.pickup_restaurant]);

  // props function call, hook start
  useMemo(() => {
    if (
      configResponseData.stripe_info &&
      Object.keys(configResponseData.stripe_info).length > 0
    )
      stripe_info_callbackFunction(configResponseData.stripe_info);
  }, [configResponseData.stripe_info, props]);
  // props function call, hook End

  // fetch countries and state api, hook start
  useMemo(() => {
    if (finalUserToken != "") {
      const countries_info = {
        user_token: finalUserToken
      };
      dispatch(fetchCountries(countries_info));
      const states_info = {
        user_token: finalUserToken
      };
      dispatch(fetchStates(states_info));
    }
  }, [dispatch, finalUserToken]);
  // fetch countries and state api, hook End

  // add data of countries api into constant,hook start
  useMemo(() => {
    if (
      countries_data &&
      countries_data.countries &&
      countries_data.countries.data
    ) {
      setCountry_info(countries_data.countries.data[0]);
    }
  }, [countries_data]);
  // add data of countries api into constant,hook end

  // add data of states api into constant,hook start
  useMemo(() => {
    if (states_data && states_data.states && states_data.states.data) {
      setState_info(states_data.states.data);
    }
  }, [states_data]);
  // add data of states api into constant,hook end

  // add data of address api into constant,hook start
  useMemo(() => {
    if (address_data && address_data.address && address_data.address.data) {
      setCheckout_address_user(address_data.address.data);
    }
  }, [address_data]);
  // add data of address api into constant,hook End

  //when checkout_address_user constant change then data add into constant,hook start
  useMemo(() => {
    if (
      localStorage.getItem("user") != null &&
      localStorage.getItem("access_token") != null
    ) {
      if (checkout_address_user && checkout_address_user.length > 0) {
        setSelected_address("Saved Address");
      } else {
        setSelected_address("New Address");
      }
    }
  }, [checkout_address_user]);
  //when checkout_address_user constant change then data add into constant,hook End

  // when uniqueBucketId has value hook start
  useMemo(() => {
    if (uniqueBucketId != "") {
      const bucket_info = {
        user_token: finalUserToken,
        user_local_bucket_id: uniqueBucketId,
        user_email: finalUserEmail
      };
      dispatch(fetchBucket(bucket_info));
      window.localStorage.setItem("user_local_bucket_id", uniqueBucketId);
    }
  }, [dispatch, finalUserEmail, finalUserToken, uniqueBucketId]);
  // when uniqueBucketId has value hook end

  useMemo(() =>{
    console.log('hitt it');
    //setDelivery_info(updateShippingMethod_data.update_shipping_method.object)
    const bucket_info = {
      user_token:finalUserToken,
      user_local_bucket_id:uniqueBucketId,
      user_email:finalUserEmail
    }
    dispatch(fetchBucket(bucket_info))
    if(updateShippingMethod_data && updateShippingMethod_data.available_shipping_method && updateShippingMethod_data.available_shipping_method.object && updateShippingMethod_data.available_shipping_method.object.error) {
    setDelivery_info([])
    setAvailableShippingMethods([])
    setCheckout_error(updateShippingMethod_data.available_shipping_method.object.error);
 }
 else {
   setCheckout_error(null);
 }
  },[updateShippingMethod_data && updateShippingMethod_data.update_shipping_method])

  // add data of updateshppingmethod api into constant hook start
  useMemo(() => {
    if (
      updateShippingMethod_data &&
      updateShippingMethod_data.update_shipping_method &&
      updateShippingMethod_data.update_shipping_method.object &&
      updateShippingMethod_data.update_shipping_method.request_status === true
    ) {
      setDelivery_info(updateShippingMethod_data.update_shipping_method.object);
      setCheckout_error(null);
    }
    else if(updateShippingMethod_data && updateShippingMethod_data.available_shipping_method && updateShippingMethod_data.available_shipping_method.object && updateShippingMethod_data.available_shipping_method.object.error) {
    //setDelivery_info([])
    //setAvailableShippingMethods([])
    setCheckout_error(updateShippingMethod_data.available_shipping_method.object.error);
 }
 else if (updateShippingMethod_data.available_shipping_method.responseCode === 404) {
   setBucketErrorModal(true);
 }
  }, [updateShippingMethod_data]);
  // add data of updateshppingmethod api into constant hook End

  // when delivery_info change data add into constant hook start
  useMemo(() => {
    if (delivery_info && Object.keys(delivery_info).length > 25) {
      setDelivery_cost(delivery_info.cost);
      const bucket_info = {
        user_token: finalUserToken,
        user_local_bucket_id: uniqueBucketId,
        user_email: finalUserEmail
      };
      dispatch(fetchBucket(bucket_info));
    }
  }, [delivery_info, dispatch, finalUserEmail, finalUserToken, uniqueBucketId]);
  // when delivery_info change data add into constant hook End

  // add updateItemQuantity api response data into constant hook start
  useMemo(() => {
    if (
      updateItemQuantity_data &&
      updateItemQuantity_data.update_item_qty &&
      updateItemQuantity_data.update_item_qty.object &&
      updateItemQuantity_data.update_item_qty.request_status === true
    ) {
      setUpdateItemQuantityInfo(updateItemQuantity_data.update_item_qty.object);
    }
  }, [updateItemQuantity_data]);
  // add updateItemQuantity api response data into constant hook End

  // when updateItemQuantity api have error then  response data into constant hook start
  useMemo(() => {
    if (
      updateItemQuantity_data &&
      updateItemQuantity_data.update_item_qty &&
      updateItemQuantity_data.update_item_qty.object &&
      updateItemQuantity_data.update_item_qty.request_status === false &&
      updateItemQuantity_data.update_item_qty.object.error == "Invalid Bucket"
    ) {
      setUpdateItemQuantityInfo(updateItemQuantity_data.update_item_qty.object);
      setUniqueBucketId("");
      localStorage.removeItem("user_local_bucket_id");
    }
  }, [updateItemQuantity_data]);
  // when updateItemQuantity api have error then response data into constant hook End

  // when updateItemQuantity api have error then  response data into constant hook start
  useMemo(() => {
    if (
    Object.keys(order_info).length > 0
    ) {
      setUniqueBucketId("");
      localStorage.removeItem("user_local_bucket_id");
      orderSuccessCall();
    }
  }, [order_info && order_info.request_status == true]);
  // when updateItemQuantity api have error then response data into constant hook End


  // when updateItemQuantityInfo has value hook start
  useMemo(() => {
    if (updateItemQuantityInfo) {
      const bucket_info = {
        user_token: finalUserToken,
        user_local_bucket_id: uniqueBucketId,
        user_email: finalUserEmail
      };
      dispatch(fetchBucket(bucket_info));
    }
  }, [dispatch, finalUserEmail, finalUserToken, uniqueBucketId, updateItemQuantityInfo]);
  // when updateItemQuantityInfo has value hook end

  // add bucket dci response data into constant hook start
  useMemo(() => {
    if (
      bucket_data &&
      bucket_data.bucket &&
      bucket_data.bucket.object &&
      bucket_data.bucket.request_status === true
    ) {
      setBucketInfo(bucket_data.bucket.object);
    }
  }, [bucket_data]);
  // add bucket dci response data into constant hook End

  useMemo(() =>{
    setDelivery_info(updateShippingMethod_data.available_shipping_method.data)
    const bucket_info = {
      user_token:finalUserToken,
      user_local_bucket_id:uniqueBucketId,
      user_email:finalUserEmail
    }
    console.log('texttttts', updateShippingMethod_data.available_shipping_method)
   setAvailableShippingMethods(updateShippingMethod_data.available_shipping_method.data)
  //  dispatch(fetchBucket(bucket_info))
  if(updateShippingMethod_data && updateShippingMethod_data.available_shipping_method && updateShippingMethod_data.available_shipping_method.object && updateShippingMethod_data.available_shipping_method.object.error) {
  setDelivery_info([])
  setAvailableShippingMethods([])
  setCheckout_error(updateShippingMethod_data.available_shipping_method.object.error);
} else {
  setCheckout_error(null);
}
  },[updateShippingMethod_data && updateShippingMethod_data.available_shipping_method && updateShippingMethod_data.available_shipping_method && updateShippingMethod_data.available_shipping_method.request_status === true])


  useMemo(() =>{
    if(updateShippingMethod_data && updateShippingMethod_data.available_shipping_method && updateShippingMethod_data.available_shipping_method.object && updateShippingMethod_data.available_shipping_method.object.error) {
    setDelivery_info([])
    setAvailableShippingMethods([])
    setCheckout_error(updateShippingMethod_data.available_shipping_method.object.error);
 }
  //  dispatch(fetchBucket(bucket_info))
},[updateShippingMethod_data && updateShippingMethod_data.available_shipping_method && updateShippingMethod_data.available_shipping_method.request_status === false])
  // when bucket dci have error then respone add into constant hook start
  useMemo(() => {
    if (
      bucket_data &&
      bucket_data.bucket &&
      bucket_data.bucket.object &&
      bucket_data.bucket.request_status === false &&
      bucket_data.bucket.object.error == "Invalid Bucket"
    ) {
      setBucketInfo(bucket_data.bucket.object);
      setUniqueBucketId("");
      localStorage.removeItem("user_local_bucket_id");
    }
  }, [bucket_data]);
  // when bucket dci have error then respone add into constant hook end

  // add bucketinfo data into constant hook start
  useMemo(() => {
    if (bucketInfo) {
      setBucketDciResponseData({
        Detailed_cart: bucketInfo,
        Detailed_cart_item: bucketInfo.items ? bucketInfo.items : [],
        cart_item_tip: bucketInfo && bucketInfo.fees ? bucketInfo.fees : [],
        Detailed_cart_checkout_method:
          bucketInfo && bucketInfo.available_checkout_methods
            ? bucketInfo.available_checkout_methods
            : [],
        Delivery_method:
        availableShippingMethods,
        pickup_restaurant:
          bucketInfo && bucketInfo.available_pickup_methods
            ? bucketInfo.available_pickup_methods
            : [],
        current_shipment_method:
          bucketInfo && bucketInfo.shippment_method
            ? bucketInfo.shippment_method
            : null
      });
      setLoadingData(null);
    }
  }, [bucketInfo]);
  // add bucketinfo data into constant hook End

  //fetch bucket api after fetch tip api hook start
  useMemo(() => {
    if (
      tip_data &&
      tip_data.add_tip &&
      tip_data.add_tip.object &&
      tip_data.add_tip.request_status === true
    ) {
      const bucket_info = {
        user_token: finalUserToken,
        user_local_bucket_id: uniqueBucketId,
        user_email: finalUserEmail
      };
      dispatch(fetchBucket(bucket_info));
    }
  }, [dispatch, finalUserEmail, finalUserToken, tip_data, uniqueBucketId]);
  //fetch bucket api after fetch tip api hook End

  //when fetch applyCoupon api after that data add into constant,hook start
  useMemo(() => {
    if (
      applyCoupon_data &&
      applyCoupon_data.apply_coupon &&
      applyCoupon_data.apply_coupon.request_status === true
    ) {
      setApplyCouponInfo(applyCoupon_data.apply_coupon.object);
      setApplyCouponState(applyCoupon_data.apply_coupon.request_status);
      setApplyCouponAmount(applyCoupon_data.apply_coupon.object.amount);
      const bucket_info = {
        user_token: finalUserToken,
        user_local_bucket_id: uniqueBucketId,
        user_email: finalUserEmail
      };
      dispatch(fetchBucket(bucket_info));
    } else if (
      applyCoupon_data &&
      applyCoupon_data.apply_coupon &&
      applyCoupon_data.apply_coupon.request_status === false &&
      applyCoupon_data.apply_coupon.object.error
    ) {
      setApplyCouponAmount(0);
      setCouponErrorModal(true);
      setCouponError(applyCoupon_data.apply_coupon.object.error);
    }
  }, [applyCoupon_data, dispatch, finalUserEmail, finalUserToken, uniqueBucketId]);
  //when fetch applyCoupon api after that data add into constant,hook End

  //when fetch applyCoupon api after that data add into constant,hook start
  useMemo(() => {
    if (
      applyCoupon_data &&
      applyCoupon_data.apply_gift &&
      applyCoupon_data.apply_gift.request_status === true
    ) {
      setGift_card_validate(applyCoupon_data.apply_gift.object);
      setGift_card_validate_error(null);
    }
    else if(
      applyCoupon_data &&
      applyCoupon_data.apply_gift &&
      applyCoupon_data.apply_gift.request_status === false
    ) {
      setGift_card_validate(null);
      setGift_card_validate_error(applyCoupon_data.apply_gift.object.error)
    }
  }, [applyCoupon_data, dispatch, finalUserEmail, finalUserToken, uniqueBucketId]);
  //when fetch applyCoupon api after that data add into constant,hook End

  //when fetch removeCoupon api after that data add into constant,hook start
  useMemo(() => {
    if (
      removeCoupon_data &&
      removeCoupon_data.remove_coupon &&
      removeCoupon_data.remove_coupon.request_status === true
    ) {
      setApplyCouponInfo(removeCoupon_data.remove_coupon.object);
      setRemoveCouponStatus(removeCoupon_data.remove_coupon.request_status);
      const bucket_info = {
        user_token: finalUserToken,
        user_local_bucket_id: uniqueBucketId,
        user_email: finalUserEmail
      };
      dispatch(fetchBucket(bucket_info));
    }
  }, [dispatch, finalUserEmail, finalUserToken, removeCoupon_data, uniqueBucketId]);
  //when fetch removeCoupon api after that data add into constant,hook End

  //when payment_token data change then fetch paymentcheckout api ,hook start
  useMemo(() => {
    console.log('addressid', address_info.address_id );
    if (payment_complete === true && payment_token != "" && address_info && address_info.address_id != undefined) {

        console.log('memo hit 1');
        setOrder_loader(true);
        setCart_empty_click(false);
        const process_centeralized_payment =
          configResponseData.stripe_info &&
          Object.keys(configResponseData.stripe_info).length > 0 &&
          configResponseData.stripe_info.STRIPE_ACCOUNT_ID
            ? "true"
            : undefined;
        const payment_checkout_info = {
          final_user_checkout_token: finalUserToken,
          Unique_bucket_Id: uniqueBucketId,
          payment_token: payment_token,
          gatewayId: bucketDciResponseData.Detailed_cart_checkout_method[0].id,
          notes_restaurant: inputValues.notes_restaurant,
          final_user_checkout_email: finalUserEmail,
          process_centeralized_payment: process_centeralized_payment,
          cardNumber : configResponseData.stripe_info.PAYMENT_GATEWAY == "authnet" ? inputValues.cardNumber : '',
          expiryMonth : configResponseData.stripe_info.PAYMENT_GATEWAY == "authnet" ? inputValues.cardExpMonth : '',
          expiryYear : configResponseData.stripe_info.PAYMENT_GATEWAY == "authnet" ? inputValues.cardExpYear : '',
          cvv : configResponseData.stripe_info.PAYMENT_GATEWAY == "authnet" ? inputValues.cardCvv : '',
          orderDate:localdate,
          orderTime:localtime,
          cardExpiry:cardconnectExpiry,
          giftcard_number:inputValues.giftcheck != false ? inputValues.giftcard_number : "",
          giftcard_pin:inputValues.giftcheck != false ? inputValues.giftcard_pin : ""
        };
        dispatch(paymentCheckout(payment_checkout_info));
        setPayment_complete(false);
      //
      // else if (
      //   user_address_id == null &&
      //   localStorage.getItem("user") != null &&
      //   localStorage.getItem("access_token") != null
      // ) {
      //   console.log('memo hit 2');
      //   setOrder_loader(true);
      //   setCart_empty_click(false);
      //   const address_info = {
      //     final_user_checkout_email: finalUserEmail,
      //     final_user_checkout_token: finalUserToken,
      //     first_name: inputValues.first_name,
      //     last_name: inputValues.last_name,
      //     address: inputValues.address,
      //     city: inputValues.city,
      //     state: inputValues.state,
      //     country: inputValues.country,
      //     postal_code: inputValues.postal_code,
      //     telephone: inputValues.telephone,
      //     email: inputValues.email
      //   };
      //   dispatch(addAddress(address_info));
      //   setPayment_complete(false);
      // } else if (
      //   localStorage.getItem("user") === null &&
      //   localStorage.getItem("access_token") === null &&
      //   user_address_id === null
      // ) {
      //   console.log('memo hit 3');
      //   setOrder_loader(true);
      //   setCart_empty_click(false);
      //   const process_centeralized_payment =
      //     configResponseData.stripe_info &&
      //     Object.keys(configResponseData.stripe_info).length > 0 &&
      //     configResponseData.stripe_info.STRIPE_ACCOUNT_ID
      //       ? "true"
      //       : undefined;
      //   const payment_checkout_info = {
      //     final_user_checkout_token: finalUserToken,
      //     address: inputValues.address,
      //     addressId: undefined,
      //     Unique_bucket_Id: uniqueBucketId,
      //     payment_token: payment_token,
      //     city: inputValues.city,
      //     country: inputValues.country,
      //     email: inputValues.email,
      //     first_name: inputValues.first_name,
      //     gatewayId: bucketDciResponseData.Detailed_cart_checkout_method[0].id,
      //     last_name: inputValues.last_name,
      //     telephone: inputValues.telephone,
      //     notes_restaurant: inputValues.notes_restaurant,
      //     postal_code: inputValues.postal_code,
      //     state: inputValues.state,
      //     final_user_checkout_email: finalUserEmail,
      //     process_centeralized_payment: process_centeralized_payment,
      //     cardNumber : configResponseData.stripe_info.PAYMENT_GATEWAY == "authnet" ? inputValues.cardNumber : '',
      //     expiryMonth : configResponseData.stripe_info.PAYMENT_GATEWAY == "authnet" ? inputValues.cardExpMonth : '',
      //     expiryYear : configResponseData.stripe_info.PAYMENT_GATEWAY == "authnet" ? inputValues.cardExpYear : '',
      //     cvv : configResponseData.stripe_info.PAYMENT_GATEWAY == "authnet" ? inputValues.cardCvv : '',
      //     orderDate:localdate,
      //     orderTime:localtime
      //   };
      //   dispatch(paymentCheckout(payment_checkout_info));
      //   setPayment_complete(false);
      // }

    }
  }, [bucketDciResponseData.Detailed_cart_checkout_method, configResponseData.stripe_info, dispatch, finalUserEmail, finalUserToken, payment_complete, payment_token, uniqueBucketId, user_address_id]);
  //when payment_token data change then fetch paymentcheckout api ,hook End

  //add data of paymentcheckout api into constant,hook start
  useMemo(() => {
    if (
      paymentCheckout_data &&
      paymentCheckout_data.payment_checkout &&
      paymentCheckout_data.payment_checkout.request_status === true
    ) {
      setOrder_info(paymentCheckout_data.payment_checkout);
      setUniqueBucketId("");
      localStorage.removeItem("user_local_bucket_id");
      orderSuccessCall();
      localStorage.clear();
      setOrder_loader(false);
      setCart_empty_click(true);
      if(currentmerchantId != null) {
        localStorage.removeItem(`localdate-${currentmerchantId}`);
        localStorage.removeItem(`localtime-${currentmerchantId}`);

      }

    } else if (
      paymentCheckout_data &&
      paymentCheckout_data.payment_checkout &&
      paymentCheckout_data.payment_checkout.request_status === false
    ) {
      setCheckout_error(paymentCheckout_data.payment_checkout.object.error);
      setPayment_token("");
      setOrder_now_click(false);
    }
  }, [paymentCheckout_data]);
  //add data of paymentcheckout api into constant,hook End

  // add data of addAddress api into constant,hook start
  useMemo(() => {
    if (
      addAddress_data &&
      addAddress_data.add_address &&
      addAddress_data.add_address.object &&
      addAddress_data.add_address.request_status === true
    ) {
      setAddress_info(addAddress_data.add_address.object);
      setCheckout_error(null);
    }
    else if (
      addAddress_data &&
      addAddress_data.add_address &&
      addAddress_data.add_address.object &&
      addAddress_data.add_address.object.error &&
      addAddress_data.add_address.request_status === false
    ) {
      setAddress_info([]);
      setCheckout_error(addAddress_data.add_address.object.error);
      //setCheckoutSectionClass('addressError');
    }
    else if (addAddress_data.add_address.responseCode === "404") {
      setBucketErrorModal(true);
    }
  }, [addAddress_data]);
  // add data of addAddress api into constant,hook End

  // add data of addAddress api into constant,hook start
  useMemo(() => {
    if (payment_token != "") {
      setCheckout_error(null);
      setStripe_error(null);
    }
  }, [payment_token]);

  //when data of address_info change then fetch paymentcheckout api,hook start
  useMemo(() => {
    if (address_info && Object.keys(address_info).length > 0) {
      const process_centeralized_payment =
        configResponseData.stripe_info &&
        Object.keys(configResponseData.stripe_info).length > 0 &&
        configResponseData.stripe_info.STRIPE_ACCOUNT_ID
          ? "true"
          : undefined;
      const payment_checkout_info = {
        final_user_checkout_token: finalUserToken,
        address: undefined,
        addressId: address_info.address_id,
        Unique_bucket_Id: uniqueBucketId,
        payment_token: payment_token,
        city: undefined,
        country: undefined,
        email: undefined,
        first_name: undefined,
      //  gatewayId: bucketDciResponseData.Detailed_cart_checkout_method[0].id,
        last_name: undefined,
        telephone: undefined,
        notes_restaurant: inputValues.notes_restaurant,
        postal_code: undefined,
        state: undefined,
        final_user_checkout_email: finalUserEmail,
        process_centeralized_payment: process_centeralized_payment
      };
    //  dispatch(paymentCheckout(payment_checkout_info));
    const bucket_info = {
      final_user_checkout_token: finalUserToken,
      Unique_bucket_Id: uniqueBucketId,
      final_user_checkout_email: finalUserEmail,
    };
      dispatch(getShippingMethod(bucket_info))
    }
  }, [address_info]);
  //when data of address_info change then fetch paymentcheckout api,hook End

  // hooks end

  // component function start
  // deliveryhandler function start
  const deliveryhandler = event => {
setCurrentShipSelected('shipmentselected');
    const update_shipping_method_info = {
      final_user_token: finalUserToken,
      final_user_email: finalUserEmail,
      Unique_bucket_Id: uniqueBucketId,
      shippingId: event.target.value
    };
    dispatch(updateShippingMethod(update_shipping_method_info));
  };
  // deliveryhandler function End

  // incrementNew function start
  const incrementNew = (value1, value2, value3, value4) => {
    setLoadingData(value4);
    const update_item_qty_info = {
      final_user_token: finalUserToken,
      bucket_id: value3,
      final_user_email: finalUserEmail,
      bucketItemId: value1,
      quantity: value2 + 1
    };
    dispatch(updateItemQuantity(update_item_qty_info));
  };
  // incrementNew function end

  // decrementNew function start
  const decrementNew = (value1, value2, value3, value4) => {
    setLoadingData(value4);
    const update_item_qty_info = {
      final_user_token: finalUserToken,
      bucket_id: value3,
      final_user_email: finalUserEmail,
      bucketItemId: value1,
      quantity: value2 - 1
    };
    dispatch(updateItemQuantity(update_item_qty_info));
  };
  // decrementNew function end

  // incrementwithAddon function start
  const incrementwithAddon = (value1, value2, value3) => {
    setLoadingData(value3);
    const update_item_qty_info = {
      final_user_token: finalUserToken,
      bucket_id: uniqueBucketId,
      final_user_email: finalUserEmail,
      bucketItemId: value1,
      quantity: value2 + 1
    };
    dispatch(updateItemQuantity(update_item_qty_info));
  };
  // incrementwithAddon function end

  // decrementwithAddon function start
  const decrementwithAddon = (value1, value2, value3) => {
    setLoadingData(value3);
    const update_item_qty_info = {
      final_user_token: finalUserToken,
      bucket_id: uniqueBucketId,
      final_user_email: finalUserEmail,
      bucketItemId: value1,
      quantity: value2 - 1
    };
    dispatch(updateItemQuantity(update_item_qty_info));
  };
  // incrementwithAddon function end

  // tiphandlerchange function start
  const tiphandlerchange = event => {
    if (
      bucketDciResponseData.cart_item_tip &&
      bucketDciResponseData.cart_item_tip.length > 0
    ) {
      bucketDciResponseData.cart_item_tip.map((tip, index) => {
        const tip_info = {
          final_user_token: finalUserToken,
          final_user_email: finalUserEmail,
          Unique_bucket_Id: uniqueBucketId,
          taxId: tip.fee_id,
          taxRate: event.target.value
        };
        dispatch(addTip(tip_info));
      });
    }
  };
  // tiphandlerchange function End

  //handlerApplyCoupon function start
  const handlerApplyCoupon = () => {
    setRemoveCouponStatus(false);
    const apply_coupon_info = {
      final_user_checkout_token: finalUserToken,
      final_user_checkout_email: finalUserEmail,
      Unique_bucket_Id: uniqueBucketId,
      apply_coupoon: applyCoupoon
    };
    dispatch(applyCoupon(apply_coupon_info));
  };
  //handlerApplyCoupon function End

  const handleStateChange = (e) => {
    setInputValues({
      ...inputValues,
      state: e.target.value
    })
    console.log('inputValues', e.target.value);
    const selectedState = state_info && state_info.length > 0 ? state_info.filter((item,i)=>item.id == e.target.value): [];
    setselectedStateData(selectedState);
    console.log('selectedStateData', selectedStateData);
    };


  //handlerApplyCoupon function start
  const handlerApplyGiftCard = (event) => {
    //setRemoveCouponStatus(false);
    const apply_giftcard_info = {
      final_user_checkout_email: finalUserEmail,
      final_user_checkout_token: finalUserToken,
      giftcard_number: inputValues.giftcard_number,
      giftcard_pin: inputValues.giftcard_pin,
      bucket_id: uniqueBucketId,
    };
    dispatch(applyGiftCard(apply_giftcard_info));
  };
  //handlerApplyCoupon function End

  //handlerRemoveCoupon function start
  const handlerRemoveCoupon = () => {

    if(bucketDciResponseData.Detailed_cart && bucketDciResponseData.Detailed_cart.applied_coupons && Object.keys(
      bucketDciResponseData.Detailed_cart.applied_coupons
    ).length > 0) {

      setApplyCouponAmount(0);
      const remove_coupon_info = {
        final_user_checkout_token: finalUserToken,
        final_user_checkout_email: finalUserEmail,
        Unique_bucket_Id: uniqueBucketId,
        apply_coupoon: Object.keys(bucketDciResponseData.Detailed_cart.applied_coupons)[0]
      };
      dispatch(removeCoupon(remove_coupon_info));

    }
    else {

      setApplyCouponAmount(0);
      const remove_coupon_info = {
        final_user_checkout_token: finalUserToken,
        final_user_checkout_email: finalUserEmail,
        Unique_bucket_Id: uniqueBucketId,
        apply_coupoon: applyCoupoon
      };
      dispatch(removeCoupon(remove_coupon_info));

    }


  };
  //handlerRemoveCoupon function End

  //handleFirstNameChange function start
  const handleFirstNameChange = event => {
    const first_name = event.target.value;
    if (first_name.match(/^[a-zA-Z ]*$/)) {
      setFirstname_error(false);
    } else {
      setFirstname_error(true);
    }
  };
  //handleFirstNameChange function End

  const resetdates = e => {
    if(currentmerchantId != null) {
      localStorage.removeItem(`localdate-${currentmerchantId}`);
      localStorage.removeItem(`localtime-${currentmerchantId}`);

    }
    setLocalDate(null);
    setLocalTime(null);
    setLocalDateFormatted(null);
  };


  //handleLastNameChange function start
  const handleLastNameChange = event => {
    const last_name = event.target.value;
    if (last_name.match(/^[a-zA-Z ]*$/)) {
      setLastname_error(false);
    } else {
      setLastname_error(true);
    }
  };
  //handleLastNameChange function End

  //handlePhoneChange function start
  const handlePhoneChange = event => {
    const phone = event.target.value;
    const phone_digit = /^\d{10}$/;
    if (phone.length == 10) {
      setPhone_error(false);
    } else {
      setPhone_error(true);
    }
  };
  //handlePhoneChange function End

  //handleEmailChange function start
  const handleEmailChange = event => {
    const email = event.target.value;
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setEmail_error(false);
    }
  };
  //handleEmailChange function End

  //handlePostalCodeChange function start
  const handlePostalCodeChange = event => {
    const postal_code = event.target.value;
    const postal_digit = /^\d{10}$/;
    if (postal_code.length == 5) {
      setPostal_code_error(false);
    } else {
      setPostal_code_error(true);
    }

  };
  //handlePostalCodeChange function end

  //handleFieldaddress function start
  const handleFieldaddress = event => {
    setSelected_address(event.target.value);
  };
  //handleFieldaddress function end

  // deliverChooseHandle function start
  const deliverChooseHandle = () => {
      setShowmodaldelivery(true)
    };

    // close delivery modal function start
    const handleclosedelivery = () => {
      setShowmodaldelivery(false)
      };
    // close delivery modal function end

  //handleSubmit function start
  const handleSubmit = event => {
    event.preventDefault();
    setOrder_now_click(true);
    if (
      configResponseData.stripe_info &&
      configResponseData.stripe_info.PAYMENT_GATEWAY == "hps"
    ) {
      console.log("withoutstripe");
      const payment = new window.Heartland.HPS({
        publicKey: configResponseData.stripe_info.HEARTLAND_PUBLISHABLE_KEY,
        cardNumber: inputValues.cardNumber,
        cardCvv: inputValues.cardCvv,
        cardExpMonth: inputValues.cardExpMonth,
        cardExpYear: inputValues.cardExpYear,

        success: resp => {
          //this.success(resp.token_value)
          setPayment_complete(true);
          setPayment_token(resp.token_value);
        },

        error: resp => {
          //this.error(resp.error.message)
          setStripe_error(resp.error.message);
          setOrder_now_click(false);
        }
      });

      try {
        payment.tokenize();
      } catch (error) {
        console.log(error);
      }
    }

    else if (
      configResponseData.stripe_info &&
      configResponseData.stripe_info.PAYMENT_GATEWAY == "authnet"
    ) {
      console.log("with Authnet");
      setPayment_complete(true);
      setPayment_token('authnettoken');
    }

    else if (
      configResponseData.stripe_info &&
      configResponseData.stripe_info.PAYMENT_GATEWAY == "cardconnect"
    ) {
      console.log("with cardconnect");
      setPayment_complete(true);
      setPayment_token(cardconnectToken);
    }

    else if (props.stripe) {
        console.log("with stripe");
      props.stripe.createToken().then(payload => {
        console.log('stpayload', payload);
        if (payload && payload.token && payload.token.id) {
          setPayment_complete(true);
          setPayment_token(payload.token.id);
          console.log('pamentcomp', payment_complete);
          console.log('pamentcomptkn', payment_token);
        } else if (payload && payload.error) {
          setStripe_error(payload.error.message);
          setOrder_now_click(false);
        }
      });
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };
  //handleSubmit function end


  const handleStepChange = step => {
    setCurrentActiveStep(step);
    setGift_card_validate(null);
    setGift_card_validate_error(null);
    setCheckout_error(null);

    if(step === 0) {
      setCheckoutSectionClass('addresspart');

    }
    if(step === 2) {
      setCheckoutSectionClass('checkoutpart');
    }
    if(step === 1) {
  console.log('stepchanged');

    setCheckoutSectionClass('deliveryPart');

  const address_info = {
    final_user_checkout_email: finalUserEmail,
    final_user_checkout_token: finalUserToken,
    first_name: inputValues.first_name,
    last_name: inputValues.last_name,
    address: inputValues.address,
    city: inputValues.city,
    state: inputValues.state,
    country: inputValues.country,
    postal_code: inputValues.postal_code,
    telephone: inputValues.telephone,
    email: inputValues.email,
    Unique_bucket_Id: uniqueBucketId,
  };
  dispatch(addAddress(address_info));
}
  };


  //handleclosecartempty function start
  const backToMenu = () => {
    props.setCheckoutPage(false);
    setStripe_error(null);
    setCheckout_error(null);
  };
  //handleclosecartempty function End


  //cartemptyhandler function start
  const cartemptyhandler = () => {
    setShowmodal_cart_empty(true);
  };
  //cartemptyhandler function End

  //handleclosecartempty function start
  const handleclosecartempty = () => {
    setShowmodal_cart_empty(false);
  };
  //handleclosecartempty function End

  //shopclosedhandler function start
  const shopclosedhandler = () => {
    setShowmodal_shop_closed(true);
  };
  //shopclosedhandler function End

  //handlecloseShopClosed function start
  const handlecloseShopClosed = () => {
    setShowmodal_shop_closed(false);
  };
  //handlecloseShopClosed function End

  //selectedaddress function start
  const selectedaddress = event => {
    setUser_address_id(event.target.value);
  };
  //selectedaddress function End

  //handleclosecoupon function start
  const handleclosecoupon = () => {
    setCouponErrorModal(false);
  };
  //handleclosecoupon function End

  //handleclosecoupon function start
  const handleReloadBucket = () => {
    window.location.reload();
  };
  //handleclosecoupon function End

  const backToMenufunction = () => {
      setOrder_info([]);
      props.backToMenufunction();
    };

  const handleDateChange = date => {
    console.log(`Option selected:`, date);
    setStartDate(date);
    setOrderaheadSelectedDate({ selectedDate: `${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()}`});
    const weekday = ["SUNDAY","MONDAY","TUESDAY","WEDNESDAY","THURSDAY","FRIDAY","SATURDAY"];
    const selectedDay = weekday[date.getDay()];
    console.log('selectedDayselectedDay',selectedDay);
    const filteredSelectedDay = availableTimes.filter((item,i)=>item.name === selectedDay);
    console.log('filteredSelectedDay',filteredSelectedDay);
    const amTime = filteredSelectedDay && filteredSelectedDay.length > 0 ? filteredSelectedDay[0].startTime === '12am' ? '12:00am': filteredSelectedDay[0].startTime : null;
    const morningTime = filteredSelectedDay && filteredSelectedDay.length > 0 ? amTime.replaceAll('am','') : '12:00';
    const eveningTime = filteredSelectedDay && filteredSelectedDay.length > 0 ? filteredSelectedDay[0].endTime.replaceAll('pm','') : '24:00';
    const finalOpeningTimeHours = filteredSelectedDay && filteredSelectedDay.length > 0 ? moment(filteredSelectedDay[0].startTime, ["h:mm a"]).format("HH") : '00';
    const finalOpeningTimeMinutes = filteredSelectedDay && filteredSelectedDay.length > 0 ? moment(filteredSelectedDay[0].startTime, ["h:mm a"]).format("mm") : '00';
    const finalClosingTimeHours = filteredSelectedDay && filteredSelectedDay.length > 0 ? moment(filteredSelectedDay[0].endTime, ["h:mm a"]).format("HH") : '23';
    const finalClosingTimeMinutes = filteredSelectedDay && filteredSelectedDay.length > 0 ? moment(filteredSelectedDay[0].endTime, ["h:mm a"]).format("mm") : '59';


    setCurrent_startTimeHours(finalOpeningTimeHours);
    setCurrent_startTimeMinutes(finalOpeningTimeMinutes);
    setCurrent_endTimeHours(finalClosingTimeHours);
    setCurrent_endTimeMinutes(finalClosingTimeMinutes);
  };

  const handleTimeChange = date => {
    console.log(`Option selected:`, date);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    console.log('orderaheadSelectedTime', minutes);
    setStartTime(date);
    setOrderaheadSelectedTime({ selectedTime: `${hours}:${minutes === 0 ? '00' : minutes}` });
  };

  const currentrestlocaltime =
    configResponseData && configResponseData.url_info
      ? `localtime-${configResponseData.url_info.MERCHANT_ID}`
      : null;
  const currentrestlocaldate =
        configResponseData && configResponseData.url_info
          ? `localdate-${configResponseData.url_info.MERCHANT_ID}`
          : null;

  const handleSaveDateChange = e => {
    console.log(`Option selected:`, e);
    // const saveddatetime = JSON.stringify([
    //   {
    //     selectedDate: orderaheadSelectedDate.selectedDate,
    //     selectedTime: orderaheadSelectedTime.selectedTime
    //   }
    // ]);
    window.localStorage.setItem(currentrestlocaldate, orderaheadSelectedDate.selectedDate ? orderaheadSelectedDate.selectedDate : null);

    window.localStorage.setItem(currentrestlocaltime, JSON.stringify(orderaheadSelectedTime.selectedTime));
    setLocalDate(orderaheadSelectedDate.selectedDate);
    setLocalTime(orderaheadSelectedTime.selectedTime);
    const monthName = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    const selectedMonth = monthName[new Date(orderaheadSelectedDate.selectedDate).getDate()-1];
    setLocalDateFormatted(`${new Date(orderaheadSelectedDate.selectedDate).getMonth()+1} ${selectedMonth} ${new Date(orderaheadSelectedDate.selectedDate).getFullYear()}`);
    setOrderaheadactive(false);
  };


  const addressComponent =
   (
    <>
      <Form className="Loc-form" id="AddressForm">
        <Form.Row>
          <Form.Group as={Col} controlId="formBasicfname">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="First Name"
              value={inputValues.first_name}
              onChange={e =>
                setInputValues({
                  ...inputValues,
                  first_name: e.target.value
                })
              }
              onBlur={e => handleFirstNameChange(e)}
              required
            />
            {firstname_error &&
            firstname_error === true ? (
              <span className="phone-error">
                *Please enter alphabet characters only.
              </span>
            ) : null}
          </Form.Group>
          <Form.Group as={Col} controlId="formBasiclname">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Last Name"
              value={inputValues.last_name}
              onChange={e =>
                setInputValues({
                  ...inputValues,
                  last_name: e.target.value
                })
              }
              onBlur={e => handleLastNameChange(e)}
              required
            />
            {lastname_error && lastname_error === true ? (
              <span className="phone-error">
                *Please enter alphabet characters only.
              </span>
            ) : null}
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group
            as={Col}
            controlId="formBasicTelephone"
          >
            <Form.Label>Telephone/mobile</Form.Label>
            <Form.Control
              type="text"
              pattern="[0-9]*"
              maxlength="10"
              placeholder="Telephone/mobile"
              value={inputValues.telephone}
              onChange={e =>
                setInputValues({
                  ...inputValues,
                  telephone: e.target.value
                })
              }
              onBlur={e => handlePhoneChange(e)}
              required
            />
            {phone_error && phone_error === true ? (
              <span className="phone-error">
                Phone Number must be 10 digits
              </span>
            ) : null}
          </Form.Group>
          <Form.Group as={Col} controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={inputValues.email}
              onChange={e =>
                setInputValues({
                  ...inputValues,
                  email: e.target.value
                })
              }
              onBlur={e => handleEmailChange(e)}
              required
            />
            {email_error && email_error === true ? (
              <span className="phone-error">
                Please enter valid email
              </span>
            ) : null}
          </Form.Group>
        </Form.Row>
        <Form.Group controlId="formBasicaddress">
          <Form.Label>Your full address</Form.Label>
          <Form.Control
            type="text"
            placeholder="Your full address"
            value={inputValues.address}
            onChange={e =>
              setInputValues({
                ...inputValues,
                address: e.target.value
              })
            }
            required
          />
        </Form.Group>
        <Form.Row>
          <Form.Group as={Col} controlId="formBasicCity">
            <Form.Label>City</Form.Label>
            <Form.Control
              type="text"
              placeholder="City"
              value={inputValues.city}
              onChange={e =>
                setInputValues({
                  ...inputValues,
                  city: e.target.value
                })
              }
              required
            />
          </Form.Group>
          <Form.Group
            as={Col}
            controlId="formBasicPostalcode"
          >
            <Form.Label>Postal code</Form.Label>
            <Form.Control
              type="text"
              pattern="[0-9]*"
              maxlength="5"
              placeholder="Postal code"
              value={inputValues.postal_code}
              onChange={e =>
                setInputValues({
                  ...inputValues,
                  postal_code: e.target.value
                })
              }
              onBlur={e => handlePostalCodeChange(e)}
              required
            />
            {postal_code_error &&
            postal_code_error === true ? (
              <span className="phone-error">
                Postal code must be equal
                to 5 digits
              </span>
            ) : null}
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="State">
            <Form.Label>State</Form.Label>
            <Form.Control
              as="select"
              onChange={e=>handleStateChange(e)}
              required
            >
              <option value="">{selectedStateData && selectedStateData.length > 0 ? selectedStateData[0].name : 'Select state'}</option>
              {state_info && state_info.length > 0
                ? state_info.map((statedata, index) => (
                    <option
                      value={statedata.id}
                      key={index}
                    >

                      {statedata.name}
                    </option>
                  ))
                : null}
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} controlId="Country">
            <Form.Label>Country</Form.Label>
            <Form.Control
              as="select"
              onChange={e =>
                setInputValues({
                  ...inputValues,
                  country: e.target.value
                })
              }
              required
            >
              <option value={country_info.id}>
                {country_info.name}
              </option>
            </Form.Control>
          </Form.Group>
        </Form.Row>
        <Form.Group controlId="exampleForm.ControlTextarea1">
          <Form.Label>
            Notes for the restaurant
          </Form.Label>
          <Form.Control
            as="textarea"
            rows="3"
            value={inputValues.notes_restaurant}
            onChange={e =>
              setInputValues({
                ...inputValues,
                notes_restaurant: e.target.value
              })
            }
          />
        </Form.Group>
        {banner_info.MERCHANT_ADD_FEAT_WINE ? (<Form.Group controlId="exampleForm.ControlTextarea1">
        <Form.Check
          type="checkBox"
          className="alchohalcheckdiv"
          label="For Alcohol purchase - I am 21 or more than 21 years old. I will show id at the time of pickup"
          name="formHorizontalRadios"
          id="alchohalcheck"
        />
        </Form.Group>): null}
      </Form>

      {configResponseData && configResponseData.stripe_info && configResponseData.stripe_info.ENABLE_ORDER_AHEAD === true || configResponseData.is_shop_open === "true" ||
    configResponseData.is_shop_open === "True" ? (
      bucketDciResponseData && bucketDciResponseData.Detailed_cart_item &&
      bucketDciResponseData.Detailed_cart_item.length > 0 ? (
        inputValues.first_name != "" &&
        inputValues.last_name != "" &&
        inputValues.telephone != "" &&
        inputValues.telephone.length == 10 &&
        email_error === false &&
        inputValues.email != "" &&
        firstname_error === false &&
        lastname_error === false &&
        inputValues.address != "" &&
        inputValues.city != "" &&
        inputValues.postal_code != "" &&
        inputValues.postal_code.length >= 5 &&
        inputValues.postal_code.length <= 10 &&
        inputValues.state != "" &&
        inputValues.country != "" ? (
          <>

            <button
              //disabled={order_now_click ? true : false}
              className="Loc-form-btn 3"
              //onClick={}
            >
              <span>
                {!propsStatePayments.payment_checkout_loading ? (
                  "Proceed"
                ) : (
                  <span className="paymentload">
                    Proceed{" "}
                    <i class="fa fa-spinner fa-spin"></i>
                  </span>
                )}{" "}
              </span>
            </button>
          </>
        ) : (
          <button
            type="submit"
            form="AddressForm"
            className="StripeCheckout Loc-form-btn"
            value="submit"
            disabled={
              phone_error ||
              postal_code_error ||
              email_error ||
              firstname_error ||
              lastname_error
                ? true
                : false
            }
          >
            {" "}
            <span>Proceed</span>
          </button>
        )
      ) : (
        <button
          disabled={!cart_empty_click}
          className="StripeCheckout Loc-form-btn"
          onClick={() => cartemptyhandler()}
        >
          {" "}
          <span>Proceed</span>
        </button>
      )
    ) : (
      <button
        className="StripeCheckout Loc-form-btn"
        onClick={() => shopclosedhandler()}
      >
        {" "}
        <span>Proceed</span>
      </button>
    )}
    </>
  );

  //stripe_amount constant start
  const stripe_amount =
    bucketDciResponseData.Detailed_cart &&
    bucketDciResponseData.Detailed_cart.total_amount
      ? bucketDciResponseData.Detailed_cart.total_amount * 100
      : 0;
  //stripe_amount constant end
  const shipping_method_name =
    currentShippingMethodName != null ? currentShippingMethodName : null;


  const paymentComponent =  (
    <>

      {configResponseData && configResponseData.stripe_info && configResponseData.stripe_info.ENABLE_ORDER_AHEAD === true || configResponseData.is_shop_open === "true" ||
  configResponseData.is_shop_open === "True" ? (
    stripe_amount != 0 ? (
      inputValues.first_name != "" &&
      inputValues.last_name != "" &&
      inputValues.telephone != "" &&
      inputValues.telephone.length == 10 &&
      email_error === false &&
      firstname_error === false &&
      lastname_error === false &&
      inputValues.address != "" &&
      inputValues.city != "" &&
      inputValues.postal_code != "" &&
      inputValues.postal_code.length >= 5 &&
      inputValues.postal_code.length <= 10 &&
      inputValues.state != "" &&
      inputValues.country != "" ? (
        <>
          <div>
            <div className="giftcardbox">
              <Form.Check
                type="checkBox"
                label="Pay By Gift Card"
                name="formHorizontalRadios"
                id="Pay By Gift Card"
                value={inputValues.giftcheck}
                checked={inputValues.giftcheck}
                onClick={e =>
                  setInputValues({
                    ...inputValues,
                    giftcheck: !inputValues.giftcheck
                  })
                }
                onChange={console.log('inputValues.giftcheck',inputValues.giftcheck)}
              />
              {inputValues.giftcheck ? (
                <div className="form-popup giftcard" id="form">
                {gift_card_validate_error != null ? (<div className="error_gift_card">{gift_card_validate_error}</div>): null}

                    <div className="col-md-12">
                      <input
                        type="text"
                        name="giftcard_number"
                        className="input"
                        placeholder="Enter Card Number"
                        value={inputValues.giftcard_number}
                        onChange={e =>
                          setInputValues({
                            ...inputValues,
                            giftcard_number: e.target.value
                          })
                        }
                        required
                      />
                    </div>
                    <div className="col-md-12">
                      <input
                        type="password"
                        name="pin"
                        className="input"
                        placeholder="Enter Pin"
                        value={inputValues.giftcard_pin}
                        onChange={e =>
                          setInputValues({
                            ...inputValues,
                            giftcard_pin: e.target.value
                          })
                        }
                        required
                      />
                    </div>
                    <div>
                      <button className="btn btn-secondary" onClick={event => handlerApplyGiftCard(event)}>
                        Validate
                      </button>
                    </div>

                </div>
              ) : null}

              {inputValues.giftcheck && gift_card_validate && gift_card_validate != null ? gift_card_validate.sufficient_balance != false ? (<div className="giftcard_bal sufficient">Your Gift Card have enough balance to pay for this order. Please Fill out your card details to complete your order. </div>) : (<div className="giftcard_bal insufficient">Your Gift Card have {gift_card_validate.balance}. Please pay rest of the order amount via your card.</div>) : null}

            </div>
            {stripe_error != null ? (
              <span className="stripe-error stripe">
                {stripe_error}
              <br/></span>
            ) : null}
            {!propsStatePayments.payment_checkout_loading && checkout_error != null ? (
              <span className="stripe-error">
                {checkout_error}
              </span>
            ) : null}

            {
              configResponseData.stripe_info
              .PAYMENT_GATEWAY == "hps" || configResponseData.stripe_info
              .PAYMENT_GATEWAY == "authnet" ? (
              <>
                <Form
                  id="standard"
                  action=""
                  method="GET"
                >
                  <Form.Group controlId="formBasicaddress">
                    <Form.Label>
                      Card Number:
                    </Form.Label>
                    <Form.Control
                      type="tel"
                      id="standardCardNumber"
                      value={inputValues.cardNumber}
                      onChange={e =>
                        setInputValues({
                          ...inputValues,
                          cardNumber: e.target.value
                        })
                      }
                      placeholder="1234 1234 1234 1234"
                      maxlength="16"
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicaddress">
                    <Form.Label>
                      Card Expiration:
                    </Form.Label>
                  </Form.Group>
                  <Form.Row>
                    <Form.Group
                      as={Col}
                      controlId="formBasicfname"
                    >
                      <Form.Label>
                        Expiration Month *
                      </Form.Label>
                      <Form.Control
                        type="tel"
                        placeholder="MM"
                        value={
                          inputValues.cardExpMonth
                        }
                        onChange={e =>
                          setInputValues({
                            ...inputValues,
                            cardExpMonth:
                              e.target.value
                          })
                        }
                        maxlength="2"
                        required
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      controlId="formBasiclname"
                    >
                      <Form.Label>
                        Expiration Year *
                      </Form.Label>
                      <Form.Control
                        type="tel"
                        placeholder="YYYY"
                        value={
                          inputValues.cardExpYear
                        }
                        onChange={e =>
                          setInputValues({
                            ...inputValues,
                            cardExpYear:
                              e.target.value
                          })
                        }
                        maxlength="4"
                        required
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Group controlId="formBasicaddress">
                    <Form.Label>Card CVV:</Form.Label>
                    <Form.Control
                      type="tel"
                      id="standardCardCvv"
                      placeholder="CVV"
                      value={inputValues.cardCvv}
                      onChange={e =>
                        setInputValues({
                          ...inputValues,
                          cardCvv: e.target.value
                        })
                      }
                      required
                    />
                  </Form.Group>
                </Form>
              </>
            ) : configResponseData.stripe_info
            .PAYMENT_GATEWAY == "cardconnect" ? (  <iframe id="tokenframe" name="tokenframe" src="https://isv.cardconnect.com/itoke/ajax-tokenizer.html?useexpiry=true&usecvv=true&invalidcreditcardevent=true&tokenpropname=cardconnecttoken&css=%2Eerror%7Bcolor%3A%20red%3B%7Dinput%7Bwidth%3A-webkit-fill-available%3Bpadding%3A10px%3B%7Dselect%7Bwidth%3A-webkit-fill-available%3Bmax-width%3A185px%3Bpadding%3A10px%3B%7Dform%7Bmax-width%3A400px%3Bpadding%3A10px%3B%7Dlabel%7Bfont-weight%3Abold%3Bmargin-top%3A24px%3Bmargin-bottom%3A-15px%3B%3Bdisplay%3Ablock%3B%7D%3Bfont-family%3ARoboto" scrolling="no" width="500" height="330" frameborder="0"></iframe>)
              : (<CardSection />)
            }
          </div>
          {console.log(
            "configResponseData",
            configResponseData
          )}
          <button
            //disabled={order_now_click ? true : false}
            className="Loc-form-btn 3 checkoutsection"
            onClick={e => handleSubmit(e)}
          >
            <span>
              {!propsStatePayments.payment_checkout_loading ? (
                "ORDER NOW"
              ) : (
                <span className="paymentload">
                  PROCESSING{" "}
                  <i class="fa fa-spinner fa-spin"></i>
                </span>
              )}{" "}
            </span>
          </button>
        </>
      ) : (
        <button
          type="submit"
          form="AddressForm"
          className="StripeCheckout Loc-form-btn checkoutsection"
          value="submit"
          disabled={
            phone_error ||
            postal_code_error ||
            email_error ||
            firstname_error ||
            lastname_error
              ? true
              : false
          }
        >
          {" "}
          <span>ORDER NOW</span>
        </button>
      )
    ) : (
      <button
        disabled={!cart_empty_click}
        className="StripeCheckout Loc-form-btn checkoutsection"
        onClick={() => cartemptyhandler()}
      >
        {" "}
        <span>ORDER NOW</span>
      </button>
    )
  ) : (
    <button
      className="StripeCheckout Loc-form-btn checkoutsection"
      onClick={() => shopclosedhandler()}
    >
      {" "}
      <span>ORDER NOW</span>
    </button>
  )}
</>
);


  // component function end


      const ThankYou = (<div id="invoiceholder">
        <div className="col-12 head">
          <img src="/img/Thankyou.png" alt="Thankyou" className="center" />
        </div>
		 {order_info &&
                  order_info.object &&
                  order_info.object.order_detail ? (
        <div id="invoice" className="effect2">
          {/*start Title*/}
          {order_info &&
                  order_info.object &&
                  order_info.object.order_detail ? (<div id="invoice-top">
            <p><strong>Notes:</strong> {order_info.object.order_detail.note}</p>
            {/*End Title*/}
          </div>) : null}
          {/*start invoice body*/}
          <div id="message">
            <h3>Order Detail</h3>
            <div className="row">
              <div className="col-6 order-serial">
                <h6><strong>Order: </strong>{order_info.object.order_detail.orderId}</h6>
              </div>
              <div className="col-6 order-placed">
                <h6><strong>Placed At:</strong> {order_info.object.order_detail.orderedDate}</h6>
              </div>
            </div>
          </div>
        {order_info.object.order_detail.items ?
                         order_info.object.order_detail.items.map((item,index) =>(
          <div className="row rolls">
            <div className="col-6 roll-left">
              <h6><strong>{item.itemName}</strong></h6>
			  {item.addons && item.addons.length > 0 ? item.addons.map((addon,index) =>(
              <h6> {addon.addon_full_name}{addon.total_price && addon.total_price !== 0 ? ': $' : null }{addon.total_price && addon.total_price !== 0 ? addon.total_price : null }</h6>

			  )):null}
            </div>
            <div className="col-3 roll-center">
              <h6>{item.qty} X ${item.unit_price}</h6>
            </div>
            <div className=" col-3 roll-right">
              <h6><strong>${(item.qty*item.unit_price).toFixed(2)}</strong></h6>
            </div>
          </div>
		  )):null}
          <div className="row rolls">
            <div className="col-6 roll-left">
			{order_info.object.order_detail.itemsFees &&
                           order_info.object.order_detail.itemsFees.length > 0 ?
                           order_info.object.order_detail.itemsFees.map((item_fees,index) =>(
                             <h6><strong>{item_fees.name}</strong></h6>
                           )):null
                          }
            <br/>
            <br/>
            </div>
            <div className=" col-6 roll-right align-item">
               {order_info.object.order_detail.itemsFees &&
                           order_info.object.order_detail.itemsFees.length > 0 ?
                           order_info.object.order_detail.itemsFees.map((item_fees,index) =>(
                             <h6><strong>${item_fees.amount}</strong></h6>
                           )):null
                          }
            </div>
          </div>
          <div className="row rolls">
            <div className="col-6 roll-left">
			{order_info.object.order_detail.taxes &&
                            order_info.object.order_detail.taxes.length > 0 ?
                            order_info.object.order_detail.taxes.map((tax,index) =>(
                              <h6><strong>{tax.name}</strong></h6>
                            )):null
                           }
            </div>
            <div className=" col-6 roll-right">
             {order_info.object.order_detail.taxes &&
                            order_info.object.order_detail.taxes.length > 0 ?
                            order_info.object.order_detail.taxes.map((tax,index) =>(
                              <h6><strong>{tax.amount}</strong></h6>
                            )):null
                           }
            </div>
          </div>
          <div className="row rolls">
            <div className="col-6 roll-left">
              <h6><strong>Order Total</strong></h6>
            </div>
            <div className=" col-6 roll-right">
              <h6><strong>${order_info.object.order_detail.orderTotal}</strong></h6>
            </div>
          </div>
          <div className="row rolls address">
            {order_info.object.order_detail.pickupAddress ?
              (<div className="col-6 roll-left vl">
              <h6><strong>Pickup Address</strong></h6>
              <h6>{order_info.object.order_detail.pickupAddress.firstName} {order_info.object.order_detail.pickupAddress.lastName}</h6>
              <h6>{order_info.object.order_detail.pickupAddress.address1}, {order_info.object.order_detail.pickupAddress.city}</h6> <h6>{order_info.object.order_detail.pickupAddress.stateName}</h6>
              <h6>{order_info.object.order_detail.pickupAddress.mobileNumber}</h6>
            </div>):null}
            {order_info.object.order_detail.shippingAddress ?
              (<div className="col-6 roll-left vl">
              <h6><strong>Shipping Address</strong></h6>
              <h6>{order_info.object.order_detail.shippingAddress.firstName} {order_info.object.order_detail.shippingAddress.lastName}</h6>
              <h6>{order_info.object.order_detail.shippingAddress.address1}, {order_info.object.order_detail.shippingAddress.city}</h6> <h6>{order_info.object.order_detail.shippingAddress.stateName}</h6>
              <h6>{order_info.object.order_detail.shippingAddress.mobileNumber}</h6>
            </div>):null}
            <div className=" col-6 roll-right vx">
              <h6><strong>Billing Address</strong></h6>
              <h6>{order_info.object.order_detail.billingAddress.firstName} {order_info.object.order_detail.billingAddress.lastName}</h6>
              <h6>{order_info.object.order_detail.billingAddress.address1}, {order_info.object.order_detail.billingAddress.city}</h6>
			  <h6>{order_info.object.order_detail.billingAddress.stateName}</h6>
              <h6>{order_info.object.order_detail.billingAddress.mobileNumber}</h6>
              <h6>{order_info.object.order_detail.billingAddress.email}</h6>
            </div>
          </div>
          {/* Invoice Body end */}
          {/* Invoice footer start */}
          <div className="row rolls">
            <div className="col-6 roll-left">
              <h6>Payment Method</h6>
            </div>
            <div className=" col-6 roll-right">
              <h6>{order_info.object.order_detail.checkoutMethod.name}</h6>
            </div>
          </div>
          {/* Invoice footer end */}
        </div> ) : null }
      </div>);


  // component constant start that contain small part of html

  //cart details constant start
  const cart_details =
    bucketDciResponseData.Detailed_cart_item &&
    bucketDciResponseData.Detailed_cart_item.length > 0 ? (
      bucketDciResponseData.Detailed_cart_item.map((item, index) => {
        let totalprice = 0;
        totalprice = item.unit_price * item.qty;
        return (
          <div className="pamout checkout" id="pamut-number" key={index}>
            <p>{item.itemName}</p>
            <span>${Number(totalprice, 2).toFixed(2)}</span>
            <div className="count" id="countted">
              <div className="handle-counter" id="handleCounter14">
                {item.addons && item.addons.length > 0 ? (
                  <>
                    <button
                      className="counter-minus hideitnow"
                      onClick={() =>
                        decrementwithAddon(
                          item.item_id,
                          item.qty,
                          item.product_id
                        )
                      }
                    >
                      -
                    </button>
                    x {item.qty}
                    <button
                      className="counter-plus hideitnow"
                      onClick={() =>
                        incrementwithAddon(
                          item.item_id,
                          item.qty,
                          item.product_id
                        )
                      }
                    >
                      +
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="counter-minus hideitnow"
                      onClick={() =>
                        decrementNew(item.item_id, item.qty, uniqueBucketId)
                      }
                    >
                      -
                    </button>
                    x {item.qty}
                    <button
                      className="counter-plus hideitnow"
                      onClick={() =>
                        incrementNew(item.item_id, item.qty, uniqueBucketId)
                      }
                    >
                      +
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        );
      })
    ) : (
      <div className="Empty-cart pamout checkout text-center" id="pamut-number">
        <h4>Empty cart</h4>
      </div>
    );
  //cart details constant End

  //delivery_content constant start
  const delivery_content = bucketDciResponseData && bucketDciResponseData.Delivery_method &&
  bucketDciResponseData.Delivery_method.length > 0 ?
  (<div className="deliveryOptions">
  {checkout_error != null ? (
    <span className="stripe-error">
      {checkout_error}
    </span>
  ) : null}
    <Form className="delivery-form">
      <Form.Label>Services Categories</Form.Label>
      <table>
      <thead>
      <tr><td></td><td></td><td>Delivery Time</td><td>Pickup Time</td></tr>
      </thead>
      <tbody>


      {bucketDciResponseData.Delivery_method &&
      bucketDciResponseData.Delivery_method.length > 0
        ? bucketDciResponseData.Delivery_method.map(
            (checkout_delivery, index) => {
              return (
                  <tr>
                  <td className="servicename">
                <Form.Group controlId="formBasicPickup">
                  <Form.Check
                    type="radio"
                    label={checkout_delivery.name}
                    name="formHorizontalRadios"
                    id={checkout_delivery.id}
                    value={checkout_delivery.id}
                    defaultChecked={
                      bucketDciResponseData.current_shipment_method ===
                      checkout_delivery.id
                        ? true
                        : false
                    }
                    onClick={event => deliveryhandler(event)}
                    //onChange={(evt) => this.changeTitle(evt)}
                  />

                </Form.Group>
                </td>
                <td className="servicefee">
                <Form.Group controlId="formBasicPickup">
                  <Form.Text className="text-muted checkout-text">
                    ${checkout_delivery.cost}
                  </Form.Text>
                </Form.Group>
                </td>
                <td className="servicedelivery">
                <Form.Group controlId="formBasicPickup">
                  <Form.Text className="text-muted checkout-text">
                    {checkout_delivery && checkout_delivery.estimated_delivered_on ? checkout_delivery.estimated_delivered_on : null}
                  </Form.Text>
                </Form.Group>
                </td>
                <td className="servicepickup">
                <Form.Group controlId="formBasicPickup">
                  <Form.Text className="text-muted checkout-text">
                    {checkout_delivery && checkout_delivery.estimated_pickup_in ? checkout_delivery.estimated_pickup_in : null}
                  </Form.Text>
                </Form.Group>
                </td>
                </tr>
              );
            }
          )
        : (  <div className="deliveryOptions"><div className="loading-shipping"><img src="/img/menu-item-loader.gif"/></div></div>)}
        </tbody>
        </table>
    </Form>  <button
        onClick={() =>
          deliverChooseHandle()
        }
        className="btn btn-prev btn-primary btn-lg pull-right dlrvrybtn"
      >
      {!propsStateAddTip.add_tip_loading &&
      propsStateShipping &&
      !propsStateShipping.update_shipping_method_loading &&
      !propsStateBucket.bucket_loading ? (
        "Proceed"
      ) : (
        <span className="paymentload">
          Processing{" "}
          <i class="fa fa-spinner fa-spin"></i>
        </span>
      )}
      </button></div>) : (  <div className="deliveryOptions"><div className="loading-shipping">{checkout_error != null ? (
        <span className="stripe-error">
          {checkout_error}
        </span>
      ) : null}<h3>Finding Delivery Services...</h3><img src="/img/menu-item-loader.gif"/></div></div>);
  //delivery_content constant End


  const orderaheadconainer = (
    <div className="container">
    {configResponseData && configResponseData.url_info && configResponseData.url_info.ORDER_AHEAD_DAYS ?
      <div className="order-ahead">
        <h1 className="selecttimehead">Select Date and Time for your order</h1>



        <Row className="orderaheadcol">

          <Col md={6}>
            <h5>Date</h5>

          <DatePicker
           selected={startDate}
           //onChange={(date) => setStartDate(date)}
           onChange={(date) => handleDateChange(date)}
           includeDates={availableDates}
           //showTimeSelect
           dateFormat="dd/MM/yyyy"
           className="form-control dates"
         />

          </Col>
          <Col md={6}>
            <h5>Time</h5>
            {current_startTimeHours && current_startTimeHours != null ?
            <DatePicker
        selected={startTime}
        onChange={(date) => handleTimeChange(date)}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        minTime={new Date(null, null, null, current_startTimeHours, current_startTimeMinutes)}
        maxTime={new Date(null, null, null, current_endTimeHours, current_endTimeMinutes)}
        dateFormat="h:mm aa"
         className="form-control times"
      />
      :
      <DatePicker
          showTimeSelectOnly
          timeIntervals={15}
          disabled
          placeholderText="Select a Date first"
          dateFormat="h:mm aa"
          className="form-control times"
  />
    }
          </Col>
          <Col md={12}>
            <button
              className="selectdatetime"
              onClick={e => handleSaveDateChange(e)}
            >
              Submit
            </button>
          </Col>
        </Row>


      </div>
        : null}
    </div>
  );

const footerClassbutton =
  inputValues.first_name != "" &&
  inputValues.last_name != "" &&
  inputValues.telephone != "" &&
  inputValues.telephone.length == 10 &&
  inputValues.email != "" &&
  email_error === false &&
  firstname_error === false &&
  lastname_error === false &&
  inputValues.address != "" &&
  inputValues.city != "" &&
  inputValues.postal_code != "" &&
  inputValues.postal_code.length >= 5 &&
  inputValues.postal_code.length <= 10 &&
  inputValues.state != "" &&
  inputValues.country != "" ? 'step-progress noproceed' : 'step-progress nonext';

  const checkoutSteps = [
                {name: 'Address Info', component: addressComponent},
                {name: 'Delivery Options', component: delivery_content },
                {name: 'Checkout', component: paymentComponent}
              ];




  // component constant end that contain small part of html
  return (
    <>


      {props &&
      props.banner_info &&
      Object.keys(props.banner_info).length > 0 ? null : (
        <Redirect to="/" />
      )}

      {order_info &&
      Object.keys(order_info).length > 0 &&
      order_info.request_status == true ? ThankYou : (

      <div className="main1 checkout">
        <div className="row backmenu">
        <div
        className={
          !propsStatePayments.payment_checkout_loading
            ? "back-to-menu text-center"
            : "back-to-menu text-center disabled-menu"
        }
      >
        <a
        onClick={backToMenufunction}
        >
          Back To Menu
        </a>
      </div>
      </div>
        <div className="container">
          <div className="main1-wrapper">
            <div className="row">
              <div
                className={
                  !propsStateApplyCoupon.apply_coupon_loading &&
                  !propsStateRemoveCoupon.remove_coupon_loading &&
                  !propsStateAddTip.add_tip_loading &&
                  !propsStateShipping.update_shipping_method_loading &&
                  !propsStateBucket.bucket_loading
                    ? "col-lg-4 col-md-4 left-panel checkout-main-left-sidebar"
                    : "col-lg-4 col-md-4 left-panel checkout-main-left-sidebar loadingstate loadingleft"
                }
              >
                <div className="row checkout-cart-banner">
                  <div className="col-md-3">
                    <div className="top-right-logo">
                      <img src={banner_info.BANNER} />
                    </div>
                  </div>
                  <div className="col-md-9">
                    <h5>{banner_info.name}</h5>
                    <p>{banner_info.city}</p>
                  </div>
                </div>
                <div id="overlay">
                  <i class="fa fa-spinner fa-spin spin-big"></i>
                </div>

                <div className="row main-checkout-row">
                  {cart_details}

                </div>
                <div className="row Apply-Coupon">
                  <div className="Apply-Coupon-field">
                    <div className="Apply-Coupon-empty"></div>
                    <div className="Apply-Coupon-icon">
                      <img src="img/sales-coupon.png" />
                    </div>
                    <div className="Apply-Coupon-input">
                      {bucketDciResponseData.Detailed_cart ? (
                        bucketDciResponseData.Detailed_cart.applied_coupons ? (
                          Object.keys(
                            bucketDciResponseData.Detailed_cart.applied_coupons
                          ).length === 0 ? (
                            <>
                              <input
                                type="text"
                                name="ApplyCoupon"
                                placeholder="Apply Coupon"
                                value={applyCoupoon}
                                onChange={e => setApplyCoupoon(e.target.value)}
                              />
                              <button
                                type="button"
                                class="btn btn-secondary"
                                onClick={() => handlerApplyCoupon()}
                              >
                                Apply
                              </button>
                            </>
                          ) : (
                            <>
                              <span className="Applied-coupon">
                                Applied Coupon -{" "}
                                {
                                  Object.keys(
                                    bucketDciResponseData.Detailed_cart
                                      .applied_coupons
                                  )[0]
                                }
                              </span>

                              <input
                                type="hidden"
                                name="ApplyCoupon"
                                placeholder="Apply Coupon"
                                value={
                                  Object.keys(
                                    bucketDciResponseData.Detailed_cart
                                      .applied_coupons
                                  )[0]
                                }
                                onChange={e => setApplyCoupoon(e.target.value)}
                              />
                              <button
                                type="button"
                                class="btn btn-secondary remove-btun hideitnow"
                                //onClick={() => handlerRemoveCoupon()}
                              >
                                Remove
                              </button>
                            </>
                          )
                        ) : null
                      ) : null}
                    </div>

                    {applyCouponState == false ||
                    removeCouponStatus == true ? null : (
                      <div>
                        <span className="Coupon-Applied">
                          Coupon Applied Successfully
                        </span>
                      </div>
                    )}
                  </div>
                  {
                    // <div className="Free-Delivery-field">
                    //   <div className="Free-Delivery-icon">
                    //     <img src="img/Delivery_Motorbike.png" />
                    //   </div>
                    //     <div className="Free-Delivery-txt">
                    //     <p>Freee Delivery</p>
                    //     <span>Order Above $4</span>
                    //   </div>
                    //
                    // </div>
                  }
                </div>
                <div className="row Bill-Details">
                  <div className="col-md-12">
                    <p>Bill Details</p>
                  </div>
                  {bucketDciResponseData.Detailed_cart ? (
                    <div className="col-md-12">
                      <ul>
                        <li>
                          Item total
                          <span>
                            {bucketDciResponseData.Detailed_cart.sub_total ? (
                              <>
                                $
                                {Number(
                                  bucketDciResponseData.Detailed_cart.sub_total,
                                  2
                                ).toFixed(2)}
                              </>
                            ) : (
                              null
                            )}
                          </span>
                        </li>
                        {
                          // <li>
                          //   Restaurant Charges
                          //   <span>{this.state.cartdetails_checkout.object.taxes
                          //    ? (<>${Number(this.state.cartdetails_checkout.object.taxes[0]
                          //        .amount,2).toFixed(2)}</>)
                          //    : null}</span>
                          // </li>
                        }
                        {bucketDciResponseData.Detailed_cart &&
                        bucketDciResponseData.Detailed_cart.taxes
                          ? bucketDciResponseData.Detailed_cart.taxes.map(
                              (taxes, index) => (
                                <li>
                                  {taxes.name}
                                  <span>
                                    <> ${Number(taxes.amount, 2).toFixed(2)}</>
                                  </span>
                                </li>
                              )
                            )
                          : null}
                        <li>
                          Tip
                          <span>
                            <select
                              onChange={e => tiphandlerchange(e)}
                              className="form-control"
                              id="tip-select-checkout"
                            >
                              {bucketDciResponseData.cart_item_tip &&
                              bucketDciResponseData.cart_item_tip.length > 0 ? (
                                tip_rate_fees.map((item, index) => {
                                  const fee_id =
                                    bucketDciResponseData.cart_item_tip[0]
                                      .fee_id;
                                  const fee_rate =
                                    bucketDciResponseData.cart_item_tip[0].rate;
                                  const selected =
                                    fee_rate == item ? "selected" : null;
                                  return (
                                    <option
                                      value={item}
                                      key={index}
                                      selected={selected}
                                    >
                                      {item}%
                                    </option>
                                  );
                                })
                              ) : (
                                <option value="0">0%</option>
                              )}
                            </select>
                          </span>
                        </li>
                        <hr />
                        <li>
                          Tip Amount
                          <span>
                            $
                            {bucketDciResponseData.cart_item_tip &&
                            bucketDciResponseData.cart_item_tip[0]
                              ? bucketDciResponseData.cart_item_tip[0].amount
                              : "0"}
                          </span>
                        </li>
                        <hr />
                        {bucketDciResponseData.Detailed_cart &&
                        bucketDciResponseData.Detailed_cart.additional_fees
                          ? bucketDciResponseData.Detailed_cart.additional_fees.map(
                              (additional_fee, index) => (
                                <>
                                  <li>
                                    {additional_fee.name}
                                    <span>
                                      <>
                                        {" "}
                                        $
                                        {Number(
                                          additional_fee.amount,
                                          2
                                        ).toFixed(2)}
                                      </>
                                    </span>
                                  </li>
                                  <hr />
                                </>
                              )
                            )
                          : null}
                        {bucketDciResponseData.Detailed_cart.applied_coupons &&
                        Object.keys(
                          bucketDciResponseData.Detailed_cart.applied_coupons
                        ).length > 0 ? (
                          <>
                            <li>
                              Applied Coupon
                              <span>
                                {
                                  Object.keys(
                                    bucketDciResponseData.Detailed_cart
                                      .applied_coupons
                                  )[0]
                                }
                              </span>
                            </li>
                            <hr />
                            <li>
                              Coupon Discount
                              <span>
                                $
                                {
                                  Object.values(
                                    bucketDciResponseData.Detailed_cart
                                      .applied_coupons
                                  )[0]
                                }
                              </span>
                            </li>
                            <hr />
                          </>
                        ) : null}
                        <li>
                          {shipping_method_name}
                          <span>

                            {bucketDciResponseData.Delivery_method &&
                            bucketDciResponseData.Delivery_method.length > 0
                              ? `$${delivery_cost}`
                              : null}
                          </span>
                        </li>
                        <hr />
                        <li>
                          TO PAY
                          <span>
                            {bucketDciResponseData.Detailed_cart
                              .total_amount ? (
                              <>
                                $
                                {Number(
                                  bucketDciResponseData.Detailed_cart
                                    .total_amount,
                                  2
                                ).toFixed(2)}
                              </>
                            ) : null}
                          </span>
                        </li>
                      </ul>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-lg-8 col-md-4 checkout-main-right-sidebar">
                <div className="main-contant">
                  <div className={bucketDciResponseData && bucketDciResponseData.current_shipment_method && bucketDciResponseData.current_shipment_method != null ? "row shipmethodselected" : "row nocurrentmethod"} id={bucketDciResponseData && bucketDciResponseData.Delivery_method && bucketDciResponseData.Delivery_method.length > 0  ? "shipmethodsloaded" : "noshipmethods"}>
                    <div className="col-md-8">
                      {/* <div className="map-iframe" >
                                 <p>
                                   <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6860.370066073937!2d76.69101877351056!3d30.71319822657574!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fee566ec12caf%3A0xe82bcee83eb20ba5!2sPhase%208B%2C%20Industrial%20Area%2C%20Sector%2074%2C%20Sahibzada%20Ajit%20Singh%20Nagar%2C%20Punjab%20140308!5e0!3m2!1sen!2sin!4v1567093539411!5m2!1sen!2sin" width="100%" height={400} frameBorder={0} style={{border: 0}} allowFullScreen />
                                 </p>
                               </div> */}

                      <div className="address-form" id={currentShipSelected}>
                      {localdate != null ? (<h3 className="orderahead-txt">
                        Your selected date for order is {" "}<br/>
                        <span
                          className="futuredateclick"
                          //onClick={e => setOrderaheadactive(true)}
                        >
                        {localdate}
                        </span> at{" "}
                        <span
                          className="futuredateclick"
                          //onClick={e => setOrderaheadactive(true)}
                        >
                         {localtime && localtime}
                        </span>

                      </h3> ): orderaheadconainer}
                      <div className={footerClassbutton} id={checkout_error != null && currentActiveStep === 1 ? 'addressError' : checkoutSectionClass}>
                      <div className={!propsStateAddTip.add_tip_loading &&
                      propsStateShipping &&
                      !propsStateShipping.update_shipping_method_loading &&
                      !propsStateBucket.bucket_loading ? "" : "updatingshipping"}>
                        <StepZilla steps={checkoutSteps} stepsNavigation={false} onStepChange={handleStepChange} nextButtonText="Proceed"/>
                      </div>
                      </div>
                        <div
                          className={
                            !propsStatePayments.payment_checkout_loading
                              ? "back-to-menu text-center hideitnow"
                              : "back-to-menu text-center disabled-menu hideitnow"
                          }
                        >
                        <a
                        onClick={backToMenufunction}
                        >
                          Back To Menu
                        </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="Delivery-box">
                        <div className="col-md-9">
                          {
                            // <h6>Delivery time</h6>
                          }
                        </div>
                        <div className="col-md-2">
                          <i className="fa fa-clock-o"></i>
                        </div>
                        <div className="col-md-1"></div>


                        <div className="row">
                          <div className="col-md-9 secure-payment">
                            <h6>Secure payment</h6>
                          </div>
                          <div className="col-md-2 credit-icon">
                            <i
                              className="fa fa-credit-card"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <div className="col-md-3"></div>
                        </div>

                        <img src="img/ssl.png" />
                        <p className="secure-content">
                          All payments is 256 bits encrypted.
                        </p>
                      </div>

                    </div>
                    <div className="col-md-6"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>)}


      <Modal show={showmodal_cart_empty} id="modal3" size="sm">
        <Modal.Body>Cart is empty.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleclosecartempty()}>
            ok
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={couponErrorModal} id="modal3" size="sm">
        <Modal.Body>{couponError}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleclosecoupon()}>
            ok
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={bucketErrorModal} id="modal55" size="sm">
        <Modal.Body>There is some issue in your cart. Please reload your cart.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleReloadBucket()}>
            Reload
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showmodal_shop_closed} id="modal3" size="sm">
        <Modal.Body>Shop is Closed.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handlecloseShopClosed()}>
            ok
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showmodaldelivery} id="modal3" size="sm">
        <Modal.Body>Please select a delivery method.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() =>handleclosedelivery()}>
            ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default injectStripe(Checkout);
